<template>
  <!--
    Used to provide data in order to add new themes to be used for the VideoPostprocessing.
  -->
  <div class="themeElementAdd">
    <h4 class="m-0 mt-3">
      {{ elementType }}
    </h4>
    <hr class="m-0 mb-3">
    <label>{{ getLabel() }}</label>
    <input
      v-model="elementName"
      v-focus
      :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
      :placeholder="`Please enter a new ${ getLabel()}`"
      type="text"
    >
    <span
      v-show="errors.has('Name')" 
      class="badge badge-danger"
    >{{ errors.first('Name') }}</span>
    <button
      class="btn btn-primary mt-4 float-right"
      @click="addThemeElement"
    >
      <font-awesome-icon
        class="mr-2"
        icon="upload"
      />{{ $t('save') }}
    </button>
    <div class="clearfix" />
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "ThemeAdd",
  mixins: [
    errorMixin
  ],
  props: {
    themeId: {
      type: String,
      required: true
    },
    elementType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      elementName: null
    }
  },
  methods: {
    getLabel (){
      if(this.elementType.includes('Image'))
      {
        return 'Name';
      }
      return 'Text';
    },
    addThemeElement () {
      let themeElement = {
        'ThemeId': this.themeId,
        'Name': this.elementName
      };
      this.axios.post(`/VideoPostProcessing/Add${ this.elementType }`, themeElement)
        .then(() => {
          this.$emit("reloadThemeElements");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>
